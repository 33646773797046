<template>
    <div :class="{'modal modal_wrap': modalView}">
        <div :class="{'modal_popup modal_content': modalView}">
            <div :class="{'modal__context': modalView}">
              <Form actions="" id="form_lock_type" @submit="next">
                <section class="item-types">
                    <breadcrumbs v-if="!modalView && !isEdit"></breadcrumbs>
                    <h2 class="titlePage_subtitle titlePage">{{name}}</h2>
                    <span class="modal__close"
                          v-if="modalView"
                          @click="closePopup"
                    >
             <svg-icon name="times"/>
          </span>

                    <div class="row">
                        <div class="col-sm-4 order-0 order-sm-1" :class="{'col-sm-12': !checkIsSetPackage}">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <p class="text">Lock Type</p>
                                </div>
                                <div class="col-12"
                                     :class="{'col-sm-12 col-md-12': !checkIsSetPackage}">
                                    <label class="form__label form__label_row"
                                           v-for="item in selection.groups.default.choices"
                                           :key="item.id">
                                        <Field type="radio" hidden="hidden"
                                               class="form__radio"
                                               name="default"
                                               :checked="item.default"
                                               @change="selectOption(item)"
                                               :rules="'required'"
                                        />
                                        <span class="form__radio_icon"></span>
                                        <span class="form__labelTitle">{{item.name}}</span>
                                    </label>
                                    <label class="form__label form__label_row">
                                        <input type="checkbox" hidden="hidden"
                                               class="form__radio"
                                               name="adjust"
                                               :disabled="!disableAdjust"
                                               v-model="selection.adjust_triple_lock"
                                        >
                                        <span class="form__checkbox_icon"></span>
                                        <span class="form__labelTitle">Adjust Triple Lock Points for Flush Bolt matching</span>
                                    </label>

                                    <div class="row align-middle" v-if="this.selection.groups.default.colour">
                                        <div class="col-6 align-middle">
                                            <h5>Lock Colour:</h5>
                                            <p class="text_default">
                                                {{this.selection.groups.default.colour.name}}</p>
                                        </div>
                                        <div class="col-6 align-middle"
                                             @click="openColourSelection()"
                                        >
                                            <div class="colours colour_box colours_small">
                                                <img class="box__img" :src="this.selection.groups.default.colour.image"
                                                     alt="color.colour_name"
                                                     v-if="this.selection.groups.default.colour.image">
                                                <p v-else-if="this.this.selection.groups.default.colour.hexcode">
                                                    <svg-icon name="palette" class="large_icon"
                                                                       :style="{color: this.groups.selection.default.colour.hexcode}"/>
                                                </p>
                                                <p v-if="!this.selection.groups.default.colour.image && !this.selection.groups.default.colour.hexcode">
                                                    <svg-icon name="palette" class="large_icon box__img"/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 order-2" v-if="checkIsSetPackage">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <p class="text">Lock Side</p>
                                </div>
                                <!--<div class="col-12 text-center">-->
                                <!--<img :src="selection.groups.lock_side.image" alt="" class="lock_side">-->
                                <!--</div>-->
                                <div class="col-6 justify-content-center text_center" v-for="item in lockSide"
                                     :key="item.id">
                                    <img :src="item.image" alt="" class="lock_side">
                                    <label class="form__label form__label_row justify-content-center">
                                        <Field type="radio" hidden="hidden"
                                               class="form__radio"
                                               name="Lock Side"
                                               :disabled="disableSide"
                                               @change="selectOption(item)"
                                               :checked="item.default"
                                               :rules="'required'"
                                        />
                                        <span class="form__radio_icon"></span>
                                        <span class="form__labelTitle flex_none">{{item.name}}</span>
                                    </label>
                                </div>
                                <div class="col-12">
                                  <ErrorMessage class="form__error"  v-show="!disableSide"  name="Lock Side" />
                                </div>

                                <div class="col-12" v-if="selection.is_flip_door_available === true">
                                    <label class="form__label form__label_row">
                                        <input type="checkbox" hidden="hidden"
                                               class="form__radio"
                                               name="adjust"

                                               v-model="selection.flip_door"
                                        >
                                        <span class="form__checkbox_icon"></span>
                                        <span class="form__labelTitle">Flip Door</span>
                                    </label>
                                </div>

                                <div class="col-12 text_center">
                                    <hr>
                                    <p class="option_note">Please note: Looking from the outside</p>
                                </div>

                            </div>

                        </div>

                        <div class="col-sm-4 order-1" v-if="checkIsSetPackage">
                            <div class="col-sm-12">
                                <span class="text">Lock Height 123</span>
                              <Field v-slot="{ field }"  name="Lock Height Type" type="radio" :value="false">
                                <label class="form__label form__label_row"
                                       v-for="item in selection.groups.lock_height.choices"
                                       :key="item.id"
                                >
                                    <Field type="radio" hidden="hidden"
                                           class="form__radio"
                                           name="Lock Height Type"
                                           :disabled="disableHeight"
                                           @change="selectOption(item)"
                                           :checked="item.default"
                                           :value="item.id"
                                           v-bind="field"
                                          :rules="'required'"
                                    />
                                    <span class="form__radio_icon"></span>
                                    <span class="form__labelTitle">{{item.name}}</span>
                                </label>
                              </Field>
                                <div class="col-12">
                                  <ErrorMessage class="form__error"  v-show="!disableHeight"  name="Lock Height Type" />
                                </div>

                                <div class="col-12">
                                    <label class="form__label form__label_row mb-1">
                                        <input type="checkbox" hidden="hidden"
                                               class="form__radio"
                                               name="make_default"
                                               v-model="makeDefaultHeight"
                                               :disabled="disableHeight"
                                        >
                                        <span class="form__checkbox_icon"></span>
                                        <span class="form__labelTitle">Make this my default selection for</span>
                                    </label>
                                    <div v-if="this.makeDefaultHeight" class="pt-0">
                                        <span class="form__labelTitle cursor_pointer" @click="toggleDefaultDropDown">all {{this.defaultType.name}}  <svg-icon name="chevron-down"/></span>
                                        <div class="dropdown" v-if="this.showDefaultDropdown">
                                            <p class="form__labelTitle cursor_pointer" v-for="dt in this.defaultTypes"
                                               @click="changeDefaultType(dt)"> - {{dt.name}}</p>
                                        </div>
                                    </div>
                                </div>

                                <label class="form__label form__label_row">
                                    <span class="mr-5" :disabled="disableInput">Height</span>
                                    <Field type="text"
                                           class="form__input"
                                           name="Lock Height"
                                           :disabled="isInputDisabled()"
                                           v-model="selection.height"
                                           :rules="{
                                               required: true,
                                               numeric: true,
                                               min_value: this.validation.min,
                                               max_value: parseFloat(this.order.htl - this.validation.dropMinus),
                                               }"
                                    />
                                    <span class="">mm</span>
                                </label>
                              <ErrorMessage class="form__error"  v-show="!disableHeight"  name="Lock Height" />
                            </div>
                            <div class="col-12">
                                <transition name="slide" mode="out-in">
                                    <div class="lockHeight" v-if="imgHeight">
                                        <img :src="imgHeight" alt="" class="lockHeight__img">
                                        <p class="text text_default lockHeight__text">{{textHeight}}</p>
                                    </div>
                                </transition>
                            </div>

                        </div>

                    </div>

                    <template v-if="actionLinksPortalTarget">
                      <portal :to="actionLinksPortalTarget">
                        <button type="submit" form="form_lock_type" class="btn btn_transparent"
                                v-if="!isUpdate"
                               >
                          <svg-icon name="solid/step-forward"/> next
                        </button>

                        <button type="submit" form="form_lock_type" class="btn btn_transparent"
                                v-else
                               ><svg-icon name="solid/check"/>
                        </button>
                      </portal>
                    </template>
                    <template v-else>
                      <button type="submit" form="form_lock_type" class="btn btn_default"
                              v-if="!isUpdate"
                             ><svg-icon name="solid/step-forward"/> next
                      </button>

                      <button type="submit" form="form_lock_type" class="btn btn_default"
                              v-else
                             ><svg-icon name="solid/check"/> done
                      </button>
                    </template>


                </section>
              </Form>
                <transition name="fade" mode="out-in">
                    <select-colors v-show="selectColours" @onSelect="setColour"></select-colors>
                </transition>

                <transition name="fade" mode="out-in">
                    <lock-validations
                            v-if="validationPopup"
                            @accept="acceptValidation"/>
                </transition>

<!--                <transition name="fade" mode="out-in">-->
<!--                    <div class="modal modal_wrap" @keyup.esc="closeCylinderQuestion" v-if="askCylinder">-->
<!--                        <div class="modal_popup">-->
<!--                            <h2 class="form__title">Action required</h2>-->
<!--                            <span class="modal__close" @click="closeCylinderQuestion">-->
<!--                                 <svg-icon name="times"/>-->
<!--                              </span>-->

<!--                            <p class="text text__white" v-if="cylinderQuestion">Selected type of lock includes a cylinder but there is no cylinder selected.</p>-->
<!--                            <p class="text text__white" v-else>Selected type of lock does not include a cylinder but cylinder is selected. Do you want to remove the cylinder from this item?</p>-->

<!--                            <p class="text text__white">Please select one of the following options:</p>-->
<!--                            <div class="justify-content-between" v-if="cylinderQuestion">-->
<!--                                <button class="btn btn_transparent" @click="cylinderAnswer('Ignore')">I don't need a cylinder</button>-->
<!--                                <button class="btn btn_transparent" @click="cylinderAnswer('Select')">Let me choose a cylinder</button>-->
<!--                            </div>-->
<!--                            <div class="justify-content-between" v-else>-->
<!--                                <button class="btn btn_transparent" @click="cylinderAnswer('Remove')">Yes, remove it</button>-->
<!--                                <button class="btn btn_transparent" @click="cylinderAnswer('Ignore')">No, keep it</button>-->
<!--                                <button class="btn btn_transparent" @click="cylinderAnswer('Select')">Let me choose a cylinder</button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </transition>-->

            </div>
        </div>
    </div>
</template>

<script>
    import breadcrumbs from "./../../parts/breadcrumbs";

    import {calcSimpleOptionPrice} from "../../../helpers/calcPriceOption";
    import {mapState, mapGetters} from 'vuex';
    import {defineAsyncComponent} from "vue";

    const LockValidations = defineAsyncComponent(() => import('../../modals/LockValidations'));
    const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));


    export default {
        name: "Lock",
        data() {
            return {

                // selection: {},

                imgHeight: '',
                textHeight: '',

                validation: {},
                validationPopup: false,

                disableInput: false,
                disableHeight: false,
                disableAdjust: false,
                disableSide: false,
                hideFlipOption: true,
                makeDefaultHeight: false,

                enterAll: false,

                groups: {
                    default: {},
                    lock_height: {},
                    lock_side: {}
                },

                defaultTypes: [
                    {value: 20, name: 'Hinged Doors'},
                    {value: 21, name: 'Sliding Doors'},
                    {value: [20, 21], name: 'Hinged and Sliding Doors'},
                ],

                defaultType: null,

                showDefaultDropdown: false,

                askCylinder: false,
                cylinderQuestion: null,
                thisOrigCylinder: true,

            }
        },

        components: {
            SelectColors,
            breadcrumbs,
            LockValidations
        },

        props: ['options', 'package', 'checkIsSetPackage', 'actionLinksPortalTarget'],

        watch: {
            'validation'() {
                this.che();
            },
        },

        methods: {
            closeCylinderQuestion() {
                this.askCylinder = false
            },
            isInputDisabled() {
                var selected = false
                _.forEach(this.selection.groups['lock_height'].choices, (i) => {
                    if (i.default)
                        selected = true
                })

                return this.disableInput || !selected
            },

            setColour(c) {
                // let c = this.$store.getters['optionColours/selectedColour'];

                this.selection.groups.default.colour = c.colour;

                this.$store.commit('options/updateColour', {option: 'LOCK', colour: c.colour});
            },

            openColourSelection() {
                if (this.selection.groups.default.colour_group)
                    this.$store.commit('optionColours/colourGroup', this.selection.groups.default.colour_group);

                this.$store.commit('optionColours/openModal', true);
                this.$store.commit('optionColours/getColors');
            },

            selectOption(item, t, skip) {
                const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);

                let target = t;

                if (event != null && !skip)
                    target = event.target.name;

                if (target == 'Lock Height Type')
                    target = 'lock_height'
                if (target == 'Lock Side')
                    target = 'lock_side'

                _.forEach(this.selection.groups[target].choices, (i, k) => {
                    i.default = item.name == i.name;
                });

                if (target == 'default') {

                    this.selection.groups.default.colour = item.colour;
                    this.selection.groups.default.colour_group = item.colour_group;

                    this.disableAdjust = item.is_adjust_triple_lock_prints;

                    if (!this.disableAdjust) {
                        this.selection.adjust_triple_lock = false;
                    }

                    this.disableHeight = this.disableInput = (item.is_height_available === false);
                    this.disableSide = (item.is_side_available === false);

                    if (this.disableHeight) {
                        _.forEach(this.selection.groups['lock_height'].choices, (i, k) => {
                            i.orig_default = i.default
                            i.default = false;
                        });
                        this.selection.height = null;
                    } else {
                        var is_selected = false;
                        _.forEach(this.selection.groups['lock_height'].choices, (i, k) => {
                            if (i.default)
                                is_selected = true
                          this.selectOption(i, 'lock_height', true);
                        });
                        if (!is_selected)
                            _.forEach(this.selection.groups['lock_height'].choices, (i, k) => {
                                if (i.orig_default) {
                                    this.selectOption(i, 'lock_height', true);
                                    is_selected = true
                                }
                            });
                        if (!is_selected)
                            var def = null
                            _.forEach(this.userDefaults('lock_height'), (i) => {
                                if (i.type_id == this.order.item.type_id)
                                    def = i.value
                            })
                            if (def) {
                                _.forEach(this.selection.groups['lock_height'].choices, (i, k) => {
                                    if (i.name == def) {
                                        this.selectOption(i, 'lock_height', true);
                                    }
                                });
                            }
                    }

                    if (this.disableSide) {
                        _.forEach(this.selection.groups['lock_side'].choices, (i, k) => {
                            i.orig_default = i.default
                            i.default = false
                        });
                    } else {
                        var is_selected = false;
                        _.forEach(this.selection.groups['lock_side'].choices, (i, k) => {
                            if (i.default)
                                is_selected = true
                        });
                        if (!is_selected)
                            _.forEach(this.selection.groups['lock_side'].choices, (i, k) => {
                                if (i.orig_default)
                                    this.selectOption(i, 'lock_side', true);
                            });

                    }
                }

                if (target === 'lock_height') {


                    this.validation = item.calculation.with;
                    this.imgHeight = item.image;
                    this.textHeight = item.image_description;
                }

                this.che();


                if(this.isEdit) {
                    const rollbackHandler = () => {
                        this.$store.commit('orderPosition/setOption', {
                            option: 'LOCK',
                            selection: backup
                        });
                    }
                    this.$store.dispatch('orderPosition/trackChanges', rollbackHandler)
                }

                this.$store.commit('orderPosition/setOption', {
                    option: 'LOCK',
                    selection: this.selection
                });

            },

            next() {
             if( !(parseFloat(this.validation.min) <= parseFloat(this.selection.height)) || !(parseFloat(this.order.htl - this.validation.dropMinus) >= parseFloat(this.selection.height)))
               return
              /*if()*/
               /* this.$validator.validate().then(valid => {

                    if (!valid)
                        return;*/

                    if (!this.disableHeight && this.makeDefaultHeight) {
                        _.forEach(this.selection.groups['lock_height'].choices, (i) => {
                            if (i.default)
                                this.$store.dispatch('currentUser/saveDefaults', {
                                    setting: 'lock_height',
                                    types: this.defaultType.value,
                                    value: i.name
                                })
                        })
                    }

                    var cyl = null

                    _.forEach(this.order.options['default'], (o) => {
                        if (o.code == 'CYL')
                            cyl = o
                    })

                    if (!cyl)
                        _.forEach(this.order.options['available'], (o) => {
                            if (o.code == 'CYL')
                                cyl = o
                        })

                    if (cyl) {

                        var cyl_exists = false
                        _.forEach(cyl.settings.groups['default'].choices, (i) => {
                            if (i.default && i.name != 'No Cylinder')
                                cyl_exists = true
                        })

                        var this_cyl = true
                        _.forEach(this.selection.groups['default'].choices, (i) => {
                            if (i.default && i.no_cylinder)
                                this_cyl = false
                        })

                        if (cyl.is_set) {
                            if (this_cyl != this.thisOrigCylinder && this_cyl != cyl_exists) {
                              this.$store.commit('orderPosition/updateOption', 'LOCK');
                              this.askCylinder = true
                              this.cylinderQuestion = this_cyl
                                return
                            }
                        }else{
                            if (!this_cyl)
                                this.$store.commit('orderPosition/removeCylinder');
                        }

                    }

                    const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);
                    const rollbackHandler = () => {

                        this.$store.commit('orderPosition/setOption', {
                            option: 'LOCK',
                            selection: backup
                        });
                    }

                    this.$store.commit('orderPosition/setOption', {
                        option: 'LOCK',
                        selection: this.selection
                    });

                    if(this.isEdit) {
                        this.$store.dispatch('orderPosition/guessNextStep', rollbackHandler)
                    }else {
                        this.$store.commit('orderPosition/optionNext', 'LOCK');
                    }
                    // this.$store.commit('orderPosition/optionNext', 'LOCK');


               /* })*/
            },

            cylinderAnswer(answer){
              console.log('AAAAAAAAAAA = ' + answer)
                this.$store.commit('orderPosition/setOption', {
                    option: 'LOCK',
                    selection: this.selection
                });

                switch(answer){
                    case 'Remove':
                        this.$store.commit('orderPosition/removeCylinder');
                        if(!this.isEdit) {
                            this.$store.commit('orderPosition/optionNext', 'LOCK');
                        }else{
                            this.$store.commit('orderPosition/updateOption', 'CYL');
                            this.$store.dispatch('orderPosition/resetStep')
                        }
                        break;
                    case 'Select':
                        this.$store.commit('orderPosition/updateOption', 'LOCK');
                        this.$store.commit('orderPosition/showOption', 'CYL');
                        break;
                    case 'Ignore':
                        if(!this.isEdit) {
                            this.$store.commit('orderPosition/optionNext', 'LOCK');
                        }else{
                            this.$store.dispatch('orderPosition/resetStep')
                        }
                        break;

                }
            },

            checkDefault() {

                _.forEach(this.selection.groups.default.choices, (i) => {
                    if (i.default == true) {
                        this.selectOption(i, 'default');
                        if (i.no_cylinder)
                            this.thisOrigCylinder = false
                    }
                });

                _.forEach(this.selection.groups.lock_height.choices, (i) => {
                    if (i.default == true)
                        this.selectOption(i, 'lock_height');
                });

                _.forEach(this.defaultTypes, (i) => {
                    if (i.value == this.order.item.type_id)
                        this.defaultType = i
                })

                this.enterAll = true;

                this.che();
            },

            closePopup() {


                this.$store.commit('options/closeAvailable');
            },

            acceptValidation() {
                _.forEach(this.selected.option_settings, (e, s) => {
                    return this.selected.option_settings[s] = '';
                });

                this.validationPopup = !this.validationPopup;
                this.disableInput = true;
                //this.disableHeight = true;
                this.checked.type = this.selected.name = 'No Lock';
                this.checked.height = '';
                this.checked.side = '';
            },

            che() {
                if (!this.disableInput) {
                    this.validationPopup = parseFloat(this.order.htl - this.validation.dropMinus) <= parseFloat(this.validation.min) ? true : false;
                }

                let pr = '';
                _.forEach(this.selection.groups['default'].choices, (i, k) => {
                    if (i.default && i.height_image_prefix != null)
                        pr = i.height_image_prefix;
                });
                _.forEach(this.selection.groups['lock_height'].choices, (i, k) => {
                    if (i.default) {
                        this.imgHeight = i.image;
                        if (pr != null && pr !== '') {
                            let p = this.imgHeight.lastIndexOf('/');
                            this.imgHeight = this.imgHeight.substr(0, p) + '/' + pr + '-' + this.imgHeight.substr(p + 1)
                        }
                    }
                });

            },
            changeDefaultType(i) {
                this.defaultType = i
                this.showDefaultDropdown = false
            },

            toggleDefaultDropDown() {
                this.showDefaultDropdown = !this.showDefaultDropdown

            }
        },
        computed: {
            ...mapState('options', ['name', 'price', 'modalView', 'priceAllOptions']),
            ...mapState('orderItem', ['isUpdate']),
            ...mapGetters({
                order: 'orderPosition/orderPosition',
                selectColours: 'optionColours/openModal',
                userDefaults: 'currentUser/getDefault',
                isEdit: 'orderPosition/isCompleted',
                selection: 'orderPosition/getCurrentOptionSettings'
            }),

            lockSide() {
                return this.selection.groups.lock_side.choices;
            },
        },

        created() {
            // this.selection = this.$store.getters['orderPosition/getCurrentOptionSettings'];
            this.$store.dispatch('currentUser/loadDefaults', 'lock_height')
            setTimeout(() => {
                this.checkDefault();
            }, 500);
        },
    }
</script>

<style scoped lang="scss">

    @import "../../../assets/scss/utils/vars";

    .form {
        &__label {
            margin-top: 0;
            margin-bottom: 20px;

        }

        &__input {
            border: none;
            border-bottom: 2px solid $navy;
            &:disabled {
                opacity: .6;
            }
        }

        &__radio {
            &:disabled {
                & ~ .form__labelTitle {
                    opacity: .6;
                    &:hover {
                        color: $navy;
                        cursor: default;
                    }
                }
            }
        }

        &__labelTitle {
            color: $navy;
            text-align: left;
        }
    }

    span[disabled="disabled"] {
        opacity: .6;
    }

    .lock_side {
        max-height: 250px;
        width: auto;
    }
    .text__white {
        color: white;
        font-size: 1.1rem;
    }

    .btn{
        margin-left: 5px;
        margin-right: 5px;
    }

    .text_white {
        position: absolute;
        bottom: 3rem;
        left: auto;
        right: 2rem;
        display: inline-block;
        width: auto;
    }

    .modal__close {
        color: $green;
    }

    .lockHeight {
        position: relative;
        &__img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &__text {
            position: absolute;
            top: 60%;
            right: 0;
            width: 50%;
        }

    }

    .colours {
        p {
            text-align: center;
        }
    }

    .preloader__img {
        svg {
            height: 25px !important;
        }

    }

    .flex_none {
        flex: none;
    }

</style>
